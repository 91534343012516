<template>
    <div>
        <portal to="layout-default-header">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column flex-grow-1">
                    <h1 class="pt-3">
                        {{ client.user.name }}
                    </h1>
                </div>

                <div class="d-flex flex-column justify-content-end">
                    <portal-target name="client-edit-actions" />
                </div>
            </div>
        </portal>

        <wait-for-resource :resource="client">
            <side-nav-router-view
                :nav-items="navItems"
                :client="client"
            />
        </wait-for-resource>
    </div>
</template>

<script>
import {Client} from '@/models/Client';
import Role from '@/library/enumerations/Role';
import SideNavRouterView from '@/components/layouts/SideNavRouterView';

export default {
    name: 'ClientEditRouterView',
    components: {SideNavRouterView},
    props: {
        /**
         * The client identifier, passed by route.
         */
        id: {
            type: [Number, String],
            required: true,
        },
    },
    data: function() {
        return {
            client: new Client({id: this.id}),
        };
    },
    computed: {
        backRoute() {
            if (this.$me.hasRoles(Role.ADMIN)) {
                return this.$me.getLocation('members.index');
            }

            if (this.$me.hasRoles(Role.COACH)) {
                return 'users.index';
            }

            return '';
        },
        navItems() {
            return [
                {location: this.client.getLocation('edit')},
                {location: this.client.getLocation('edit.programs')},
                {location: this.client.getLocation('edit.media')},
            ];
        },
    },
    async created() {
        await this.client.fetch();
    },
};
</script>
